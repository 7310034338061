<template>
  <a-form-item>
    <label :class="'filled'">
      {{ label }}
    </label>
    <a-input
      class="travel-input"
      v-on="$listeners"
      v-bind="$attrs"
      v-decorator="[`${fieldKey}`, { initialValue, rules: finalRules }]"
    >
      <a-icon v-show="editing" slot="prefix" type="field-svg" />
    </a-input>
  </a-form-item>
</template>

<script>
export default {
  name: "TInput",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
    },
    fieldKey: {
      type: String,
    },
    rules: {
      type: Array,
    },
    editing: null,
    initialValue: null,
    required: {
      type: Boolean,
    },
    requiredMessage: {
      type: String,
    },
    form: null,
  },
  beforeMount() {
    if (this.required) {
      this.finalRules.push({
        required: true,
        message: this.requiredMessage ? this.requiredMessage : "Obrigatório",
      });
    }

    if (this.rules) this.finalRules = this.finalRules.concat(this.rules);
  },
  data() {
    return {
      finalRules: [],
    };
  },
};
</script>
